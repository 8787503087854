<template>
  <a-modal
    width="min(90%, 726px)"
    :footer="null"
    :closable="true"
    :mask="true"
    :mask-closable="false"
    :visible="show"
    @cancel="onExit">
    <div class="plausibility-report-loading-modal">
      <h3 class="flex align-center txt-32 txt-bold">
        {{ title }}
      </h3>
      <a-progress :percent="progressPercentage" :status="!!fileBlob ? undefined : 'active'" :show-info="false" stroke-color="#66c300"/>
      <p class="plausibility-modal-body-text">
        {{ infoText }}
      </p>
      <div class="plausibility-report-download-button-container">
        <a-button
            class="mb-10 w170 min-width-fit-content"
            type="primary"
            size="large"
            :loading="!fileBlob"
            @click="download">
          {{ downloadButtonText }}
        </a-button>
      </div>
    </div>
  </a-modal>
</template>

<script>

const MAX_LOADING_TIME = 60; // seconds

export default {
  name: 'PlausibilityReportLoadingModal',
  props: {
    title: {
      type: String,
      required: false,
      default: ''
    },
    show: {
      type: Boolean,
      required: true,
      default: false
    },
    onExit: {
      type: Function,
      required: true
    },
    fileBlob: {
      type: Blob,
      required: false,
      default: null
    },
    isFetching: {
      type: Boolean,
      required: true
    },
    requestTime: {
      type: Number,
      required: true
    },
    fileName: {
      type: String,
      required: true
    }
  },
  data() {
    return {};
  },
  computed: {
    infoText() {
      return !this.isFetching && !!this.fileBlob ? this.$t('components.description.plausibilityReportIsReady') : this.$t('components.description.thisCouldTakeUpToOneMinute');
    },
    progressPercentage() {
      return this.fileBlob ? 100 : Math.min(this.requestTime / MAX_LOADING_TIME * 90, 90);
    },
    downloadButtonText() {
      return this.fileBlob ? this.$t('components.description.download') : this.$t('components.description.preparing');
    }
  },
  methods: {
    download() {
      if (this.fileBlob) {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(this.fileBlob);
        link.download = this.fileName;
        link.click();
        URL.revokeObjectURL(link.href);
      }
    }
  }
};
</script>

<style scoped>
.plausibility-report-loading-modal {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 10px;
}
.plausibility-modal-body-text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  width: 100%;
  line-height: 26px;
  letter-spacing: -0.4px;
  color: #363534;
  margin-bottom: 76px;
  margin-top: 5px;
}
.plausibility-report-download-button-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: horizontal;
}
</style>
